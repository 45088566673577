const formatPriceDKK = (price: number|undefined): string => {
    let num = 0.00;
    if (price) {
        num = Math.round(price * 100) / 100;
    }

    const formattedPrice = num.toLocaleString('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
    return formattedPrice + ' kr.'
}

export default formatPriceDKK;
